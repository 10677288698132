<template>
  <v-app>
    <app-loader v-if="loader"></app-loader>
    <v-main>
      <v-container fluid fill-height class="justify-center">
        <div>
          <v-row class="justify-center">
            <v-col cols="10">
              <LottieAnimation
                :loop="true"
                :speed="1"
                :animationData="require('../assets/lotties/404.json')"
              />
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col cols="12">
              <p :class="dinamicClass">404 - No hemos podido encontrar lo que buscas</p>
              <v-btn
                color="blueMinsal"
                class="mt-8"
                rounded
                @click="ejecutarMover(), (showLoader())"
                text
                id="botomAction"
                style="text-transform: none"
                large
              >
                <p class="text-h6 text--primary ma-0">Regresar al login</p>
              </v-btn>
              <v-btn
                solid
                color="secondary"
                class="mt-8 text-no-style text-h6 text--primary"
                @click="($router.push({ name: 'dashboard' })), (showLoader())"
                :loading="loading"
              >
                Regresar al dashboard
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import LottieAnimation from "lottie-web-vue";
import AppLoader from "../components/AppLoaderComponent.vue";
export default {
  name: "forbiddenView",
  components: {
    LottieAnimation,
    AppLoader,
  },
  name: "About",
  data: () => ({
    move: 0,
    interval: null,
    loading: false,
  }),
  methods: {
    mover() {
      this.move = this.move + 10;
    },
    ejecutarMover() {
      this.$router.push("/identidad-digital");
    },
  },
  computed: {
    ...mapState("utils", ["loader", "menu"]),
    dinamicClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-h6";
        case "sm":
          return "text-h6";
        case "md":
          return "text-h4";
        case "lg":
          return "text-h2";
        case "xl":
          return "text-h2";
        case "2xl":
          return "text-h2";
      }
    },
    dinamicSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 300;
        case "sm":
          return 300;
        case "md":
          return 400;
        case "lg":
          return 500;
        case "xl":
          return 500;
        case "2xl":
          return 700;
      }
    },
  },
  beforeMount() {
    const token = localStorage.getItem("token");
    if (!token) {
      this.$router.replace("/login");
    }
  },
};
</script>
